// Here you can add other styles

.react-datetimerange-picker__wrapper{
  border: 0 !important;
}


@media screen and (max-width: 767px) {
  .header.header-sticky {
    background-color: #{$colore-flover};
  }
  .header .header-toggler {
    color: #{$high-emphasis-inverse};
  }
  .header .breadcrumb-item a {
    color: #{$medium-emphasis-inverse};
  }
  .header .breadcrumb-item.active {
    color: #{$high-emphasis-inverse};
  }

}

@include media-breakpoint-down(sm) {
  .login-card-header-left{
    display: none;
  }
  .login-card-body{
    max-width: 290px;
    text-align: center;
  }

  .ferie_legenda_destra{
    display: none;
  }

}

@include media-breakpoint-up(sm) {
  .login-card-header-top{
    display: none!important;
  }

  .ferie_legenda_mobile{
    display: none;
  }
}